<template>
	<div class="sidebar-settings">

		<div class="top">
			<h1>{{ $t('settings-navbar.title') }}</h1>
			<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close /></div>
		</div>
			
		<div class="settings-content">
			<div v-if="$auth.user().rights_name.includes('master_admin') || $auth.user().rights_name.includes('admin')" class="item-menu" @click="closeModal">
				<router-link :to="{name: 'settings-roles' }" >
					<span class="text">{{ $t('settings-navbar.roles') }}</span>
				</router-link>
			</div>
			<div v-if="$auth.user().rights_name.includes('master_admin') || $auth.user().rights_name.includes('admin')" class="item-menu" @click="closeModal">
				<router-link :to="{name: 'settings-categories' }">
					<span class="text">{{ $t('settings-navbar.categories') }}</span>
				</router-link>
			</div>
			<div class="item-menu" @click="closeModal">
				<router-link :to="{name: 'settings-notifications'}">
					<span class="text">{{ $t('settings-navbar.notifications') }}</span>
				</router-link>
			</div>
			<div class="item-menu" @click="closeModal">
				<router-link :to="{name: 'settings-profile'}">
					<span class="text">{{ $t('settings-navbar.profile') }}</span>
				</router-link>
			</div>
			<div class="item-menu" v-if="$auth.user().is_entrepreneur && !$auth.user().free_account" @click="closeModal">
				<router-link :to="{name: 'settings-subscription'}">
					<span class="text">{{ $t('settings-navbar.subscriptions') }}</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	export default {
		data() {
			return {
			};
		},
		props:{

		},
		components: {
			IconClose
		},
		async mounted() {
			
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
		}
	};
</script>